import { render, staticRenderFns } from "./CSelectTray.vue?vue&type=template&id=6c059b33&scoped=true&"
import script from "./CSelectTray.vue?vue&type=script&lang=ts&"
export * from "./CSelectTray.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c059b33",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/tmp/workspace/GPW/gpw-erp-fe-staging/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6c059b33')) {
      api.createRecord('6c059b33', component.options)
    } else {
      api.reload('6c059b33', component.options)
    }
    module.hot.accept("./CSelectTray.vue?vue&type=template&id=6c059b33&scoped=true&", function () {
      api.rerender('6c059b33', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/shared/select-tray/CSelectTray.vue"
export default component.exports